<template>
  <div class="container-fluid padding-top-layout">
    <div class="wrap">
      <div class="wrap__block">
        <div class="wrap__block__header wrap__header">
          <div class="wrap__block__header__content">
            <span>{{ $t('user_management.title_user_management') }}</span>
            <div class="helper-icon">
              <img
                :src="getSettingIcon('helper-icon.svg')"
                @mouseover="changeTooltipImage($event)"
                @mouseleave="changeTooltipImage($event, 'mouseleave')"
                alt=""
              />
              <div
                v-if="showTooltip"
                class="helper-icon tooltip"
                v-html="$t('user_management.tooltip_user_management')"
              ></div>
            </div>
          </div>
        </div>
        <div class="wrap__status">
          <div class="wrap__status__item">
            <img src="@/assets/icons/clock.svg" alt="" />
            <div class="wrap__status__item__wrap">
              <p>{{$t('common.last_update')}}</p>
              <p>{{ dateTimeFormat(statusUpdate.time) }}</p>
            </div>
          </div>
          <div class="wrap__status__item">
            <img src="@/assets/icons/userTable.svg" alt="" />
            <div class="wrap__status__item__wrap">
              <p>{{$t('common.updated_by')}}</p>
              <p>{{ statusUpdate.user || '' }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="user-group-area">
        <v-btn class="user-group-btn" @click="navigateToUserGroup()">
          <img src="@/assets/images/setting/user-group.svg" alt="" class="button-icon" />{{
            $t('user_management.button_user_group')
          }}
        </v-btn>
      </div>
      <div
        class="category-table main-table custom-table center-table user-management"
        :class="[isFullScreen && 'full-screen', isExpand ? 'expanded' : 'no-expanded']"
      >
        <data-table
          :data-source="userGrid"
          :grid-columns="userColumn"
          :init-grid="initGrid"
          :rowFocus="1"
          :totalData="totalData"
          :isHasData="isHasData"
          :cellInput="cellInput"
          :allowRightClick="true"
          :tableName="'usersSetting'"
          @changeFullScreen="onChangeFullScreen"
          @onHandleActionTable="checkValidationData"
        />
      </div>
      <dialog-popup
        :dialog="dialogPopup"
        :message="dialogMessage"
        @submit="() => checkboxHandler(false)"
        @close="closePopup()"
      />
      <dialog-popup-err :dialog="showPopupErr" :message="dialogErrMessage" @submit="handleCloseErrPopup()" />
      <confirm-email-popup
        :dialog="dialogEnableConfirm"
        @close="dialogEnableConfirm = false"
        @submit="handleUpdateTypeEmail"
      />
      <notification-popup
        :dialog="dialogNotification"
        :message="messageSavedChange"
        @submit="dialogNotification = false"
      />
    </div>
  </div>
</template>
<script>
import DialogPopup from '@/components/dialogs/question-popup';
import DialogPopupErr from '@/components/dialogs/error-popup';
import ConfirmEmailPopup from '@/components/dialogs/confirm-email-popup';
import NotificationPopup from '@/components/dialogs/notification-popup.vue';
import DataTable from '@/components/category/data-table';
import * as wjGrid from '@mescius/wijmo.grid';
import * as wjcCore from '@mescius/wijmo';
import { CollectionView, PopupPosition, Tooltip } from '@mescius/wijmo';
import { createUserApi, getListUserApi, updateUserApi, verifyEmail } from '@/api/userManagement';
import { ROUTES } from '@/router/constants';
import { mapActions, mapState } from 'vuex';
import validate, { validateBlankRowData, validateMaximumCharactor } from '@/utils/validate';
import { formatDateTime } from '@/utils/datetimeFormat';
import { AutoComplete } from '@mescius/wijmo.input';
import { toolTipCustom } from '@/utils/tooltipCustom';
import { KEYS_CODE } from '@/constants/keyboard';
import { BLANK_ID } from '@/constants/registerData';
import debounce from 'lodash/debounce';
import { UndoStack } from '@mescius/wijmo.undo';
import { GridEditAction } from '../managements/wijmo-extends';
import { batchReplacementViewCollection, emptyPromise } from '@/concerns/registerData/wijmo.helper';
import { EMAIL_VALID } from '@/constants/status';
import { ROLE } from '@/constants/role';
import { convertNestedFullWidthToHalfWidth } from '@/utils/convertHalfWidth';
import { $_helper_isNumberType } from '@/concerns/newRegisterData/wijmo.helper';
import { getStatusNameById, getColorTextStatusById } from '@/utils/statusUser';
import { getRoleName } from '@/utils/getRoleName';
import { setMinMaxSizeColumns, getWidthByTextContent } from '@/utils/calcTextWidth';

export default {
  components: {
    DataTable,
    DialogPopup,
    DialogPopupErr,
    ConfirmEmailPopup,
    NotificationPopup,
  },
  data() {
    return {
      breadCrumb: [
        {
          text: this.$t('user_management.hyperlink_home'),
          disabled: false,
          href: `${ROUTES.PRODUCTS_EMISSION}/${ROUTES.PRODUCT_LIST}`,
        },
        {
          text: this.$t('user_management.hyperlink_setting'),
          disabled: false,
          href: `${ROUTES.PRODUCTS_EMISSION}${ROUTES.SETTING}`,
        },
        {
          text: this.$t('user_management.label_user_management'),
          disabled: true,
          href: `${ROUTES.PRODUCTS_EMISSION}${ROUTES.USER_MANAGEMENT}`,
        },
      ],
      userGrid: null,
      data: [],
      userRoles: [],
      check_status: null,
      userColumn: [],
      dialogPopup: false,
      dialogMessage: this.$t('popup.popup_disable_access_user'),
      dialogCancelBtn: false,
      isChecked: null,
      countErr: 0,
      dataTableApiFailed: null,
      apiErr: [],
      hdrTips: new Tooltip({
        position: PopupPosition.Above,
        showAtMouse: true,
        showDelay: 600,
        cssClass: 'hdr-tip',
      }),
      statusUpdate: {
        time: null,
        user: null,
      },
      totalData: null,
      idsUpdate: [],
      dataRedo: [],
      restoreSelection: null,
      pastingData: null,
      undoStack: null,
      canUndo: false,
      canRedo: false,
      actionCount: 0,
      dataDisabledAction: null,
      stackUndoWhenUncheck: null,
      eventChangeStatus: null,
      idsUpdateFailed: [],
      listEmailValidate: [],
      flexGrid: null,
      emailErrFormat: false,
      showTooltip: false,
      showPopupErr: false,
      dialogErrMessage: '',
      isHasAdmin: true,
      dialogEnableConfirm: false,
      listOriginalData: [],
      dialogNotification: false,
      cellInput: ['email', 'name', 'role_id'],
      isEditDataTable: false,
    };
  },
  computed: {
    ...mapState('userData', ['currentUser']),
    ...mapState('commonApp', ['loadMore', 'isFilter', 'isExpand']),
    ...mapState('registerData', ['isFullScreen']),
    ...mapState('actionsTable', ['startAction']),
    ...mapState('actionsTable', ['dataAction']),

    isHasData() {
      return this.isEditDataTable || this.canUndo || this.canRedo;
    },
    messageSavedChange() {
      return this.$t('user_management.message_saved_changes');
    },
  },

  mounted() {
    this.updateBreadCrumb(this.breadCrumb);
    this.actionUpdateIsLoadingTable(true);
    this.userColumn = this.getUserColumns();
    getListUserApi({
      contractor_id: this.currentUser.user.contractor_id,
    })
      .then((userData) => {
        this.totalData = userData.data.length;
        this.getRolesName(userData.roles);
        this.data = userData.data.map((user) => {
          return {
            ...user,
            valid_email: this.getValueEmail(user?.valid_email),
            status: [EMAIL_VALID.INVALID_EMAIL, EMAIL_VALID.CONFIRMING_EMAIL].includes(
              this.getValueEmail(user?.valid_email),
            )
              ? null
              : user?.status,
            color_valid_email: getColorTextStatusById(user?.valid_email),
          };
        });

        this.defineTable(this.data);
        this.statusUpdate = {
          time: userData.updated_at_latest,
          user: userData.user_updated,
        };
        this.actionUpdateIsLoadingTable(false);
      })
      .catch(() => {
        this.actionUpdateIsLoadingTable(false);
      });
    this.createUndoStack();
  },
  watch: {
    startAction(newValue, _) {
      if (newValue.undo) {
        this.undoStack.undo();
      } else if (newValue.redo) {
        this.undoStack.redo();
      }
    },

    canUndo(newValue) {
      this.actionUpdateStatusBtn({
        undo: newValue,
        redo: this.canRedo,
      });
    },

    canRedo(newValue) {
      this.actionUpdateStatusBtn({
        undo: this.canUndo,
        redo: newValue,
      });
    },
    isHasAdmin() {
      setTimeout(() => {
        if (!this.isHasAdmin) {
          this.showPopupErr = true;
          this.dialogErrMessage = this.$t('popup.popup_change_admin_to_user');
        } else {
          this.data = [];
          this.userGrid = null;
          this.undoStack.clear();
          this.getUsers();
        }
      }, 100);
    },
  },
  methods: {
    ...mapActions('commonApp', ['updateBreadCrumb', 'actionUpdateIsLoadingTable', 'actionUpdateIsLoading']),
    ...mapActions('actionsTable', ['actionUpdate', 'actionUpdateStatusBtn']),

    initGrid(grid) {
      grid.scrollPositionChanged.addHandler(
        debounce((s, e) => {
          if (!this.$store.state.registerData.isFullScreen) {
            return;
          }

          if (s.viewRange.bottomRow >= s.rows.length - 1) {
            s.deferUpdate(() => {
              const lastClientId = grid.itemsSource.itemCount;

              for (let index = 1; index <= 100; index++) {
                s.itemsSource.addNew(this.blankData(lastClientId + index));
              }

              s.itemsSource.commitNew();
              s.itemsSource.clearChanges();
            });
          }
        }, 100),
      );

      document.addEventListener('keydown', (e) => {
        if (
          (e.metaKey || e.ctrlKey) &&
          [
            KEYS_CODE.DOWN_ARROW,
            KEYS_CODE.UP_ARROW,
            KEYS_CODE.LEFT_ARROW,
            KEYS_CODE.RIGHT_ARROW,
            KEYS_CODE.ENTER,
          ].includes(e.keyCode)
        ) {
          e.preventDefault();
        }
      });

      grid.hostElement.addEventListener(
        'keydown',
        (e) => {
          // console.log('keydown: ', e);
          if (e.metaKey || e.ctrlKey) {
            if (e.keyCode === KEYS_CODE.DOWN_ARROW) {
              const currentSelection = grid.selection;
              const cellRange = new wjGrid.CellRange(grid.rows.length - 1, currentSelection.col);
              grid.selection = cellRange;

              // re-select after add more
              setTimeout(() => {
                grid.selection = cellRange;
              }, 200);
            } else if (e.keyCode === KEYS_CODE.UP_ARROW) {
              const currentSelection = grid.selection;
              grid.selection = new wjGrid.CellRange(0, currentSelection.col);
            } else if (e.keyCode === KEYS_CODE.RIGHT_ARROW) {
              const currentSelection = grid.selection;
              grid.selection = new wjGrid.CellRange(currentSelection.row, grid.columns.length - 1);
            } else if (e.keyCode === KEYS_CODE.LEFT_ARROW) {
              const currentSelection = grid.selection;
              grid.selection = new wjGrid.CellRange(currentSelection.row, 1);
            }
          }

          if (e.keyCode === KEYS_CODE.ENTER) {
            if (grid.selection.row === grid.rows.length - 1) {
              const lastClientId = grid.itemsSource.itemCount;

              grid.deferUpdate(() => {
                grid.itemsSource.addNew(this.blankData(lastClientId + 1));

                grid.itemsSource.commitNew();
                grid.itemsSource.clearChanges();
              });
            }
          }
        },
        false,
      );

      // add tooltip
      this.flexGrid = grid;
      this.flexGrid.onSelectionChanged(null);
      grid.onSelectionChanged(null);

      grid.pasted.addHandler((s, e) => {
        if (e.range.row === e.range.row2) {
          // paste one row
          s.onItemsSourceChanged();
        }
        this.checkTableChangeData();
      });

      grid.pastingCell.addHandler((s, e) => {
        const col = e.panel.columns[e.col];
        const view = s.collectionView;
        const source = view.sourceCollection;
        const currentItem = source[e.range.row] || {};
        if (col.binding === 'email') {
          if (currentItem.id && currentItem.id !== BLANK_ID && currentItem.valid_email !== this.$t(EMAIL_VALID.INVALID_EMAIL)) {
            e.cancel = true;
          }
        }
      })

      grid.formatItem.addHandler((s, e) => {
        const colBinding = e.panel.columns[e.col].binding;
        const colBindingTooltip = ['email', 'role_id', 'initial_password', 'status', 'valid_email'];
        if (!colBindingTooltip.includes(colBinding)) {
          return;
        }
        if (e.panel == s.columnHeaders) {
          if (colBinding === 'email') {
            toolTipCustom(e, 't1', this.$t('user_management.tooltip_email'), this.hdrTips);
          }

          if (colBinding === 'role_id') {
            toolTipCustom(
              e,
              't2',
              `
                            ${this.$t('user_management.tooltip_specify_operation_authority')}<br/>
                            ${this.$t('user_management.tooltip_role_management')}<br/>
                            ${this.$t('user_management.tooltip_role_approval')}<br/>
                            ${this.$t('user_management.tooltip_role_general')}
                            `,
              this.hdrTips,
            );
          }
          if (colBinding === 'initial_password') {
            toolTipCustom(e, 't3', this.$t('user_management.tooltip_initial_password'), this.hdrTips);
          }
          if (colBinding === 'status') {
            toolTipCustom(e, 't4', this.$t('user_management.tooltip_status'), this.hdrTips);
          }
        }
        if (e.panel == s.cells && colBinding === 'status') {
          const validEmailValue = s.rows[e.row]._data?.valid_email;
          if (validEmailValue !== this.$t(EMAIL_VALID.CONFIRMED_EMAIL)) {
            e.cell.innerHTML = '';
          }
          const roleId = s.rows[e.row]._data?.role_id;
          if (roleId === ROLE.ADMIN) {
            wjcCore.addClass(e.cell, 'is-admin-read-only');
          }
        }
        if(e.panel == s.cells && colBinding === 'valid_email') {
          const item = s.rows[e.row]._data
          if(item?.id && item?.id !== BLANK_ID) {
            e.cell.innerHTML = `<button class="wj-cell-maker"><div class="${item?.color_valid_email}"> ${item?.valid_email}</div></button>`
            e.cell.addEventListener('click', () => {
              this.onConfirmEmailClicking({item})
            })
          } else {
            e.cell.innerHTML = ''
          }
        }
      });
      // Checkbox handler
      grid.beginningEdit.addHandler((s, e) => {
        let column = s.columns[e.col];
        let rowValue = s.rows[e.row]._data;
        this.check_status = rowValue;
        if (
          column.binding === 'email' &&
          rowValue.valid_email !== this.$t(EMAIL_VALID.INVALID_EMAIL) &&
          rowValue.id &&
          rowValue.id !== BLANK_ID
        ) {
          e.cancel = true;
        }

        if (column.binding !== 'status') {
          return;
        } else {
          if (rowValue.valid_email !== this.$t(EMAIL_VALID.CONFIRMED_EMAIL)) {
            return;
          }
        }

        this.stackUndoWhenUncheck = new GridEditAction(s, e);

        if (rowValue.role_id !== ROLE.ADMIN) {
          this.handleCheckBox(rowValue);
        }
        e.cancel = true;
      });
      grid?.cellEditEnded.addHandler((s, e) => {
        let newValue = s.getCellData(e.row, e.col);
        if (s.columns[e.col].binding === 'role_id' && newValue === ROLE.ADMIN) {
          s.setCellData(e.row, 6, true, false, true);
        }
        this.handleConvertFullWidthToHaftWidth(s, e);
        this.checkTableChangeData();
      });
      grid.scrollPositionChanged.addHandler(debounce(() => {
        grid.formatItem.addHandler((s, e) => {
          const colBinding = e.panel.columns[e.col].binding;
          const colBindingTooltip = ['email', 'role_id', 'initial_password', 'status', 'valid_email'];
          if (!colBindingTooltip.includes(colBinding)) {
            return;
          }
          if(e.panel == s.cells && colBinding === 'valid_email') {
            const item = s.rows[e.row]._data
            if(item?.id && item?.id !== BLANK_ID) {
              e.cell.innerHTML = `<button class="wj-cell-maker"><div class="${item?.color_valid_email}"> ${item?.valid_email}</div></button>`
              e.cell.addEventListener('click', () => {
                this.onConfirmEmailClicking({item})
              })
            } else {
              e.cell.innerHTML = ''
              e.cell.removeEventListener('click', () => {})
            }
          }
        });
        grid.refresh()
      }, 200))
    },
    handleConvertFullWidthToHaftWidth(s, e) {
      const { row, col } = e.range;
      let cellData = s.getCellData(row, col, false)?.toString();
      const isNumber = $_helper_isNumberType(cellData);
      cellData = cellData && isNumber ? cellData : convertNestedFullWidthToHalfWidth(cellData);
      s.setCellData(row, col, cellData, false, true);
    },
    getValueEmail(value) {
      return getStatusNameById(value);
    },
    getNumberValueToParams(type) {
      if (type === '確認する') {
        return null;
      } else if (type === '確認中') {
        return 0;
      } else {
        return 1;
      }
    },
    navigateToUserGroup() {
      this.$router.push({ path: ROUTES.PRODUCTS_EMISSION + ROUTES.GROUPS_MANAGEMENT });
    },
    handleAddNew(data) {
      createUserApi({ data: data })
        .then((res) => {
          this.emailErrFormat = false;
          // Format status first row
          if (this.userGrid._view.length === 1) {
            this.defineTable();
          }

          this.userGrid.itemsAdded.length = 0;
          this.apiErr = [];

          this.totalData = this.data.length;
          this.userGrid.itemsAdded.length = 0;

          this.statusUpdate = {
            time: res.latest_record.updated_at_latest,
            user: res.latest_record.user_updated,
          };

          // update data after add
          this.userGrid.deferUpdate(() => {
            const insertedData = res.record_insert || [];
            let index = insertedData.length - 1;
            let gridIndex = this.userGrid.itemCount - 1;
            while (index >= 0) {
              this.userGrid.sourceCollection[gridIndex].id = insertedData[index].id;
              this.userGrid.sourceCollection[gridIndex].initial_password = insertedData[index].initial_password;
              this.userGrid.sourceCollection[gridIndex].status = insertedData[index].status;
              index--;
              gridIndex--;
            }
          });
          // Clear undo stack after added
          this.undoStack.clear();
        })
        .catch((err) => {
          this.showMessage(err);
        });
    },
    defineTable(data) {
      this.userColumn = this.getUserColumns();
      this.dataTableApiFailed = data?.map(
        (item, index) =>
          item && {
            ...item,
            index: index + 1,
          },
      );
      this.initialView();

      this.listEmailValidate = this.userGrid.items.filter((item) => item?.id).map((item) => item.email);

      this.$nextTick(() => {
        this.scrollToTop();
      });
    },
    async getUsers() {
      const userData = await getListUserApi({
        contractor_id: this.currentUser.user.contractor_id,
      });
      this.data = userData.data.map((user) => {
        return {
          ...user,
          valid_email: this.getValueEmail(user?.valid_email),
          status: [EMAIL_VALID.INVALID_EMAIL, EMAIL_VALID.CONFIRMING_EMAIL].includes(
            this.getValueEmail(user?.valid_email),
          )
            ? null
            : user?.status,
          color_valid_email: getColorTextStatusById(user?.valid_email),
        };
      });

      this.totalData = userData.data.length;
      this.statusUpdate = {
        time: userData.updated_at_latest,
        user: userData.user_updated,
      };
      this.getRolesName(userData.roles);
      this.data = userData.data.map((user) => {
        return {
          ...user,
          valid_email: this.getValueEmail(user?.valid_email),
          _lastUpdated: new Date().valueOf(),
          color_valid_email: getColorTextStatusById(user?.valid_email),
        };
      });
      this.defineTable(this.data);
    },
    updateTypeEmailToTable(id, newValue) {
      const indexUserEditing = this.userGrid._view.findIndex((user) => user.id === id);
      this.userGrid.beginUpdate();
      this.userGrid._view[indexUserEditing].valid_email = newValue;
      if (newValue === this.$t('user_management.status_status_confirming')) {
        this.userGrid._view[indexUserEditing].color_valid_email = 'btn_confirming';
      }
      this.userGrid.endUpdate();
    },
    getUserColumns() {
      const plusWidthMark = 25;
      return [
        {
          header: '#',
          binding: 'id',
          allowSorting: false,
          isReadOnly: true,
          visible: false,
        },
        {
          header: this.$t('user_management.table_email'),
          binding: 'email',
          minWidth: getWidthByTextContent(this.$t('user_management.table_email')) + plusWidthMark,
          maxWidth: 980,
          allowSorting: false,
          isRequired: false,
          wordWrap: true,
          cssClassAll: 'single-row cell-background',
        },
        {
          header: this.$t('user_management.table_name'),
          binding: 'name',
          minWidth: getWidthByTextContent(this.$t('user_management.table_name')),
          maxWidth: 980,
          allowSorting: false,
          isRequired: false,
          wordWrap: true,
          cssClassAll: 'no-tooltip single-row',
        },
        {
          header: this.$t('user_management.table_initial_password'),
          binding: 'initial_password',
          minWidth: getWidthByTextContent(this.$t('user_management.table_initial_password')) + plusWidthMark,
          maxWidth: 980,
          allowSorting: false,
          isReadOnly: true,
          wordWrap: true,
          cssClassAll: 'single-row',
        },
        {
          header: this.$t('user_management.table_role_id'),
          binding: 'role_id',
          minWidth: getWidthByTextContent(this.$t('user_management.table_role_id')) + plusWidthMark,
          maxWidth: 980,
          allowSorting: false,
          isRequired: false,
          dataMap: new wjGrid.DataMap(this.userRoles, 'id', 'value'),
          dropDownCssClass: 'drop-down-custom',
          editor: new AutoComplete(document.createElement('div'), {
            placeholder: this.$t('56_pattern.placeholder'),
            itemsSource: this.userRoles,
            selectedValuePath: 'id',
            displayMemberPath: 'value',
          }),
          wordWrap: true,
          cssClassAll: 'single-row',
        },
        {
          header: this.$t('user_management.table_valid_email'),
          binding: 'valid_email',
          minWidth: getWidthByTextContent(this.$t('user_management.table_valid_email')),
          maxWidth: 980,
          allowSorting: false,
          wordWrap: true,
          cssClass: 'btn-db',
          cssClassAll: 'no-tooltip single-row email-verify',
        },
        {
          header: this.$t('user_management.table_status'),
          binding: 'status',
          minWidth: getWidthByTextContent(this.$t('user_management.table_status')) + plusWidthMark,
          width: '*',
          allowSorting: false,
          isRequired: false,
          dataType: 'Boolean',
          wordWrap: true,
          cssClassAll: 'single-row',
        },
      ];
    },
    handleCheckBox(value) {
      if (value?.status) {
        this.dialogPopup = true;
        this.dialogMessage = this.$t('popup.popup_disable_access_user');
        this.dialogCancelBtn = true;
      } else {
        this.checkboxHandler(true);
      }
    },

    onConfirmEmailClicking(rowData) {
      if(!rowData?.item?.id || rowData?.item?.id === BLANK_ID) {
        return
      }
      if (rowData?.item?.valid_email === this.$t(EMAIL_VALID.INVALID_EMAIL)) {
        this.dialogEnableConfirm = true;
        this.rowEditing = rowData;
      }
    },

    checkboxHandler(check) {
      if (this.check_status.id === undefined || !this.check_status.email || !this.check_status.role_id) {
        return;
      }
      // check role before uncheck
      if (
        !check &&
        this.check_status.role_id === 3 &&
        this.userGrid.items.filter((item) => item.role_id === 3 && item.status).length <= 1
      ) {
        this.isHasAdmin = false;
        return;
      }
      this.isEditDataTable = true;
      this.userGrid.beginUpdate();
      this.userGrid.currentItem.status = check;
      this.userGrid.endUpdate();
      if (this.stackUndoWhenUncheck) {
        this.undoStack.pushAction(this.stackUndoWhenUncheck);
        this.stackUndoWhenUncheck = null;
      }
      this.closePopup();
    },

    validateEmail(email) {
      if (email?.length > 0) {
        if (!validate.regexMail.test(email)) {
          // TODO
          this.emailErrFormat = true;
          return this.$t('user_management.message_wrong_format_mail');
        }
      }
      this.emailErrFormat = false;
      return null;
    },

    validateApiFailed(name, item) {
      if (!item?.id && item?.email?.length >= 1) {
        if (this.apiErr.some((item) => item.keys.includes(name))) {
          return this.apiErr.find((element) => element.keys.includes(name)).mess;
        }
      } else {
        if (this.idsUpdateFailed.includes(item.id)) {
          if (this.apiErr.some((item) => item.keys.includes(name))) {
            return this.apiErr.find((element) => element.keys.includes(name)).mess;
          }
        }
      }
      return null;
    },

    getError(item, propName) {
      if (validateBlankRowData(item, ['name', 'email', 'role_id'])) {
        return null;
      }
      switch (propName) {
        case 'name':
          return validateMaximumCharactor(item[propName], 50);
        case 'email':
          return (
            this.validateEmail(item[propName]) ||
            validateMaximumCharactor(item[propName], 128) ||
            this.validateEmailAlreadyExists(item) ||
            this.validateApiFailed(propName, item)
          );
        case 'role_id':
          return this.emptyCell(item[propName]);
      }
      return null;
    },
    emptyCell(item) {
      if (item === undefined || item === null) {
        return this.$t('validation.error_required_field');
      }
      return null;
    },
    closePopup() {
      this.dialogPopup = false;
      this.dialogCancelBtn = true;
      this.isHasAdmin = true;
      this.dialogMessage = this.$t('popup.popup_disable_access_user');
    },
    getRolesName(roleDatas) {
      this.userRoles.length = 0;
      roleDatas.forEach((item) => {
        item.name = getRoleName(item.name);
        this.userRoles.push({
          id: item.id,
          value: this.$t(item.name),
        });
      });
    },
    dateTimeFormat(dateTime) {
      return formatDateTime(dateTime);
    },
    validateEmailAlreadyExists(item) {
      if ((!item?.id || item?.id === BLANK_ID) && this.listEmailValidate.includes(item.email)) {
        return this.$t('user_management.message_email_exist');
      } else {
        if (this.idsUpdate.includes(item.id) && this.listEmailValidate.includes(item.email)) {
          return this.$t('user_management.message_email_exist');
        }
      }
      return null;
    },
    getSettingIcon(image) {
      if (image) {
        return require(`@/assets/icons/${image}`);
      }
      return '';
    },

    changeTooltipImage(event, type) {
      if (type === 'mouseleave') {
        event.target.src = this.getSettingIcon('helper-icon.svg');
        event.target.parentElement.style.cursor = 'default';
        this.showTooltip = false;
      } else {
        event.target.src = this.getSettingIcon('helper-icon_active.svg');
        event.target.parentElement.style.cursor = 'pointer';
        this.showTooltip = true;
      }
    },
    blankData(clientRowId) {
      return {
        clientRowId: clientRowId,
        id: BLANK_ID,
        contractor_id: null,
        role_id: null,
        name: null,
        email: null,
        status: null,
        valid_email: null,
      };
    },
    addBlankItemsToView(count) {
      const lastClientId = this.userGrid.itemCount;
      for (let index = 1; index <= count; index++) {
        this.userGrid.addNew(this.blankData(lastClientId + index));
      }

      this.userGrid.commitNew();
      this.userGrid.clearChanges();
    },
    scrollToTop() {
      if (!this.flexGrid) {
        return;
      }

      let rc = this.flexGrid.cells.getCellBoundingRect(0, 0, true);
      this.flexGrid.scrollPosition = new wjcCore.Point(this.flexGrid.scrollPosition.x, -rc.top);
    },
    initialView() {
      if (this.userGrid) {
        this.data = this.userGrid.items.filter((item) => item.id && item.id !== BLANK_ID);
      }

      this.userGrid = new CollectionView([...this.data], {
        trackChanges: true,
      });
      this.addBlankItemsToView(10);
      this.flexGrid.columnGroups = this.getUserColumns();
      this.userGrid.currentItem = null;
      this.listOriginalData = JSON.parse(JSON.stringify(this.userGrid.items));
      setMinMaxSizeColumns(this.flexGrid, this.data);
    },
    isHasSomeAdmin(dataList) {
      return dataList.some(
        (item) =>
          item.role_id === 3 && item.status === true && item.valid_email === this.$t(EMAIL_VALID.CONFIRMED_EMAIL),
      );
    },
    handleUpdateTypeEmail() {
      const rowData = this.rowEditing.item;
      this.dialogEnableConfirm = false;
      if (!rowData) {
        return;
      }

      const params = {
        id: rowData.id,
        contractor_id: rowData.contractor_id,
      };
      verifyEmail(params).then(() => {
        this.updateTypeEmailToTable(rowData.id, this.$t(EMAIL_VALID.CONFIRMING_EMAIL));
      });
    },

    onChangeFullScreen(isFullScreen) {
      if (isFullScreen) {
        this.addBlankItemsToView(100);
        this.scrollToTop();
      } else {
        // this.initialView();

        this.$nextTick(() => {
          this.scrollToTop();
        });
      }
    },
    showMessage(errors) {
      this.showPopupErr = true;
      this.dialogErrMessage = Object.keys(errors.errors)
        .map((key) => errors.errors[key]?.join('\n'))
        .join('\n');
    },
    handleCloseErrPopup() {
      this.showPopupErr = false;
      this.isHasAdmin = true;
      this.dialogPopup = false;
    },
    validateData(item) {
      let isValid = true;
      Object.keys(item).forEach((key) => {
        switch (key) {
          case 'name':
            if (!item[key] || item[key].length > 50) {
              isValid = false;
            }
            break;
          case 'email':
            if (
              !item[key] ||
              item[key].length > 128 ||
              this.validateEmail(item[key]) !== null ||
              this.validateEmailAlreadyExists(item) !== null ||
              this.validateApiFailed(item[key], item) !== null
            ) {
              isValid = false;
            }
            break;
          case 'role_id':
            if (this.emptyCell(item[key]) !== null) {
              isValid = false;
            }
            break;
        }
      });

      return isValid;
    },
    createUndoStack() {
      this.undoStack = new UndoStack('#undoable-table', {
        maxActions: 50,
        stateChanged: (s) => {
          this.canUndo = s.canUndo;
          this.canRedo = s.canRedo;
        },
      });
    },
    checkValidationData() {
      this.userGrid.getError = this.getError;
      this.submitData();
    },
    submitData() {
      let edited = {};
      let editedBlank = {};
      let added = {};

      let self = this;
      let indexBlank = 0;
      self.listOriginalData.forEach(function (item) {
        let newItemIndex = self.userGrid.items.findIndex((currentItem) => currentItem.id === item.id);
        const indexWithBlankId = newItemIndex + indexBlank;
        let user = self.userGrid.items[indexWithBlankId];
        if (
          user.status !== item.status ||
          user.contractor_id !== item.contractor_id ||
          user.role_id !== item.role_id ||
          user.name !== item.name ||
          user.email !== item.email
        ) {
          let prepareUser = {
            id: user.id,
            contractor_id: self.currentUser.user.contractor_id,
            role_id: user.role_id,
            name: user.name,
            email: user.email,
            status: user.status === null ? 1 : user.status ? 1 : 0,
          };
          const indexPayload = self.userGrid.items.indexOf(user);
          if (indexPayload !== -1) {
            prepareUser['index'] = indexPayload + 1;
          }
          if (user.hasOwnProperty('orderId')) {
            delete prepareUser.orderId;
          }
          if (!self.validateData(prepareUser)) {
            return;
          }
          if (!self.isHasSomeAdmin(self.userGrid.items)) {
            self.isHasAdmin = false;
            self.userGrid.refresh();
            return;
          }
          if (user.id === BLANK_ID) {
            delete prepareUser.id;
            editedBlank[indexWithBlankId] = prepareUser;
            indexBlank += 1;
          } else {
            edited[indexWithBlankId] = prepareUser;
          }
        }
      });

      this.userGrid.itemsAdded.forEach((user, index) => {
        if (!user.email || !user.name || !user.role_id) {
          return;
        }
        let itemCustom = {
          ...user,
          status: 1,
          contractor_id: this.currentUser.user.contractor_id,
        };
        delete itemCustom.valid_email;
        delete itemCustom.initial_password;
        const indexPayload = this.userGrid.items.indexOf(user);
        if (indexPayload !== -1) {
          itemCustom['index'] = indexPayload + 1;
        }
        if (user.hasOwnProperty('orderId')) {
          delete itemCustom.orderId;
        }
        if (!this.validateData(itemCustom)) {
          return;
        }
        if (!this.isHasSomeAdmin(this.userGrid.items)) {
          this.isHasAdmin = false;
          this.userGrid.refresh();
          return;
        }
        added[index] = itemCustom;
      });

      // call api
      const editedPromise = Object.values(edited).length
        ? updateUserApi({ data: Object.values(edited) })
            .then((res) => {
              this.listOriginalData = JSON.parse(JSON.stringify(this.userGrid.items));
              this.undoStack.clear();
              this.isEditDataTable = false;
            })
            .catch((error) => {
              this.showMessage(error);
            })
        : emptyPromise();

      const editedBlankPromise = Object.values(editedBlank).length
        ? createUserApi({ data: Object.values(editedBlank) })
            .then((res) => {
              this.afterDataSuccess(this.userGrid.itemsEdited, editedBlank, res);
            })
            .catch((error) => {
              this.showMessage(error);
            })
        : emptyPromise();

      const addedPromise = Object.values(added).length
        ? createUserApi({ data: Object.values(added) })
            .then((res) => {
              this.afterDataSuccess(this.userGrid.itemsAdded, added, res);
            })
            .catch((error) => {
              this.showMessage(error);
            })
        : emptyPromise();
      
      if (!Object.values(editedBlank).length && !Object.values(edited).length && !Object.values(added).length) {
        this.actionUpdateIsLoading(false);
        return;
      }

      Promise.all([editedPromise, editedBlankPromise, addedPromise])
        .then(() => {
          this.actionUpdateIsLoading(false);
          if (!this.showPopupErr) {
            this.dialogNotification = true;
          }
        })
        .catch(() => {
          this.actionUpdateIsLoading(false);
        });
    },
    afterDataSuccess(collection, items, response) {
      const addedReplacement = {
        id: 'id',
        status: 'status',
        initial_password: 'initial_password',
        valid_email: 'valid_email',
        contractor_id: 'contractor_id',
        color_valid_email: 'color_valid_email',
      };
      this.statusUpdate = {
        time: response.latest_record.updated_at_latest,
        user: response.latest_record.user_updated,
      };
      let recordInsert = response.record_insert.map((user) => {
        return {
          ...user,
          valid_email: this.getValueEmail(user?.valid_email),
          color_valid_email: getColorTextStatusById(user?.valid_email),
        };
      });
      this.userGrid.deferUpdate(() => {
        batchReplacementViewCollection(collection, recordInsert, Object.keys(items), addedReplacement);
        collection.length = 0;
      });
      this.listOriginalData = JSON.parse(JSON.stringify(this.userGrid.items));
      this.undoStack.clear();
    },
    checkTableChangeData() {
      setTimeout(() => {
        this.isEditDataTable = this.userGrid?.itemsEdited.length > 0 || this.userGrid?.itemsAdded.length > 0;
      }, 100);
    },
  },
};
</script>

<style lang="scss" scoped>
@import './styles/index.scss';
.tabs-content {
  margin: 0px -20px;
}
@include desktop {
  .tabs-content {
    margin: 0px;
  }
}
</style>
